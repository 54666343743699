import styled from 'styled-components';
import { margin, variant, width, WidthProps } from 'styled-system';
import {
  BorderProps,
  ColorProps,
  LayoutProps,
  MarginProps,
  PropsWithPseudo,
  TypographyProps,
} from '../../../theme/styledProps';
import { VariantColor } from '../../../theme/theme';
import { truncatedStyle } from '../Text/Text';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'errorSecondary'
  | 'subtleInactive'
  | 'subtleActive'
  | 'link'
  | 'warning';

export type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonShape = 'square' | 'circle';

export type Pseudo =
  | '&:not(.disabled):hover'
  | '&:not(.disabled):focus'
  | '&:not(.disabled):active'
  | '&[data-focus-visible-added]:not(.disabled):focus';

interface ButtonIconProps {
  isLeft?: boolean;
  isRight?: boolean;
  isLoading?: boolean;
  isIconButton?: boolean;
  size?: ButtonSize;
}

export const ButtonIcon = styled.i<ButtonIconProps>(
  ({ isLeft, isRight, isLoading }) => ({
    margin: 0,
    marginRight: isLeft ? 8 : 0,
    marginLeft: isRight ? 8 : 0,
    opacity: isLoading ? 0 : 1,
  }),

  ({ isIconButton }) =>
    variant<TypographyProps, ButtonSize>({
      prop: 'size',
      variants: {
        small: {
          fontSize: 'small',
        },

        medium: {
          fontSize: isIconButton ? 'body' : 'small',
        },

        large: {
          fontSize: 'h6',
        },
      },
    }),
);

export const RawButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  line-height: initial;
  text-shadow: none;
  height: initial;
  box-sizing: border-box;

  &:not(.disabled):hover,
  &:not(.disabled):active,
  &:not(.disabled):focus {
    text-shadow: none;
    background: none;
  }

  &[data-focus-visible-added]:not(.disabled):focus {
    outline: auto 2px Highlight;
    outline: auto 5px -webkit-focus-ring-color;
  }

  i:not(${ButtonIcon}) {
    margin: 0;
    padding: 0;
  }
`;

export interface StyledButtonProps extends MarginProps, WidthProps {
  variant: ButtonVariant;
  variantColor?: VariantColor;
  size: ButtonSize;
  disabled?: boolean;
  isTruncated?: boolean;
  shape: ButtonShape;
}

export const StyledButton = styled(RawButton)<StyledButtonProps>(
  ({ theme, disabled, variant: variantProp, isTruncated }) => ({
    fontFamily: theme.fontFamily,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'initial',
    padding: variantProp === 'link' ? '0 2px' : '0 16px',
    position: 'relative',
    fontWeight: 500,
    display: 'inline-flex',
    appearance: 'none',
    userSelect: 'none',
    outline: 'none',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    lineHeight: 1.2,
    flexShrink: 0,
    transition: `background ${theme.transitions.normal}, color ${theme.transitions.normal}`,
    ...(isTruncated ? { maxWidth: '100%' } : {}),

    '&[data-focus-visible-added]:not(.disabled):focus': {
      outline: 'none',
      boxShadow: `0px 0px 0px 2px ${theme.colors.primary['300']}, inset 0px 0px 0px 1px ${theme.colors.primary['500']}`,
      zIndex: 1,
    },
  }),
  ({ theme, variantColor, color }) =>
    variant<
      PropsWithPseudo<
        ColorProps & TypographyProps & { boxShadow?: string; transition?: string },
        Pseudo
      >,
      ButtonVariant
    >({
      variants: {
        primary: {
          bg: 'primary.500',
          color: 'white',

          '&:not(.disabled):focus': {
            bg: 'primary.500',
          },

          '&:not(.disabled):hover': {
            bg: 'primary.600',
          },

          '&:not(.disabled):active': {
            bg: 'primary.700',
          },
        },

        secondary: {
          bg: 'bg',
          color: 'neutral.600',
          boxShadow: `${theme.shadows['200']}, inset 0px 0px 0px 1px ${theme.colors.neutral['300']}`,

          '&:not(.disabled):focus': {
            bg: 'bg',
          },

          '&:not(.disabled):hover': {
            bg: 'neutral.100',
          },

          '&:not(.disabled):active': {
            bg: 'neutral.200',
          },
        },

        error: {
          bg: 'error.600',
          color: 'bg',

          '&:not(.disabled):focus': {
            bg: 'error.600',
          },

          '&:not(.disabled):hover': {
            bg: 'error.700',
          },

          '&:not(.disabled):active': {
            bg: 'error.800',
          },

          '&[data-focus-visible-added]:not(.disabled):focus': {
            boxShadow: `0px 0px 0px 2px ${theme.colors.error['200']}, inset 0px 0px 0px 1px ${theme.colors.error['500']}`,
          },
        },

        errorSecondary: {
          bg: 'bg',
          color: 'error.600',
          boxShadow: `${theme.shadows['200']}, inset 0px 0px 0px 1px ${theme.colors.error['300']}`,

          '&:not(.disabled):focus': {
            bg: 'bg',
          },

          '&:not(.disabled):hover': {
            bg: 'error.50',
          },

          '&:not(.disabled):active': {
            bg: 'error.100',
          },

          '&[data-focus-visible-added]:not(.disabled):focus': {
            boxShadow: `0px 0px 0px 2px ${theme.colors.error['200']}, inset 0px 0px 0px 1px ${theme.colors.error['500']}`,
          },
        },

        subtleInactive: {
          bg: 'transparent',
          color: 'neutral.600',

          '&:not(.disabled):hover': {
            bg: 'neutral.200',
          },

          '&:not(.disabled):active': {
            bg: 'neutral.300',
          },
        },

        subtleActive: {
          bg: 'transparent',
          color: 'primary.500',

          '&:not(.disabled):hover': {
            bg: 'primary.100',
          },

          '&:not(.disabled):active': {
            bg: 'primary.200',
          },
        },

        link: {
          bg: 'transparent',
          color: `${color || `${variantColor || 'primary'}.500`}`,
          transition: `color ${theme.transitions.fast}`,

          '&:not(.disabled):hover': {
            color: `${color || `${variantColor || 'primary'}.600`}`,
          },
        },

        warning: {
          bg: 'yellow.100',
          color: 'yellow.500',

          '&:not(.disabled):focus': {
            bg: 'yellow.100',
          },

          '&:not(.disabled):hover': {
            bg: 'yellow.200',
          },

          '&:not(.disabled):active': {
            bg: 'yellow.100',
          },
        },
      },
    }),

  ({ variant: variantProp }) =>
    variant<LayoutProps & TypographyProps, ButtonSize>({
      prop: 'size',
      variants: {
        small: {
          height: variantProp === 'link' ? 'auto' : 24,
          fontSize: 'small',
          lineHeight: variantProp === 'link' ? 'revert' : '24px',
        },

        medium: {
          height: variantProp === 'link' ? 'auto' : 36,
          fontSize: 'body',
          lineHeight: variantProp === 'link' ? 'revert' : '36px',
        },

        large: {
          height: variantProp === 'link' ? 'auto' : 48,
          fontSize: 'body',
          lineHeight: variantProp === 'link' ? 'revert' : '48px',
        },
      },
    }),

  () =>
    variant<BorderProps, ButtonShape>({
      prop: 'shape',
      variants: {
        square: {
          borderRadius: 'medium',
        },

        circle: {
          borderRadius: 'full',
        },
      },
    }),

  margin,
  width,
);

interface ButtonTextProps {
  isLoading?: boolean;
  isTruncated?: boolean;
}

export const ButtonText = styled.span<ButtonTextProps>(({ isLoading, isTruncated }) => ({
  opacity: isLoading ? 0 : 1,
  ...(isTruncated ? truncatedStyle : {}),
}));

interface AddonProps {
  isLoading?: boolean;
}

export const LeftAddon = styled.span<AddonProps>(({ isLoading }) => ({
  opacity: isLoading ? 0 : 1,
  marginRight: 8,
}));

export const RightAddon = styled.span<AddonProps>(({ isLoading }) => ({
  opacity: isLoading ? 0 : 1,
  marginLeft: 8,
}));
